<template>
  <div class="customization">
    <!--   导航-->
    <div class="dh">
      <div class="navigation">
        <div class="ico" @click="go()">
          <img class="icon" src="../../assets/img15.png">
          <p class="designation">华钛信息</p>
        </div>
        <div class="selectivity">
          <div class="alternative">
            <div class="xzx1 xzxz1">
              <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
              <div class="nav1 nav">
                <div class="navxx">
                  <span>行业方案</span>
                  <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                  <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzData" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img188.png">
                </div>
              </div>
            </div>
            <div class="xzx2 xzxz1">
              <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
              <div class="nav2 nav">
                <div class="navxx">
                  <span>自主产品</span>
                  <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                  <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img189.png">
                </div>
              </div>
            </div>
            <div class="xzx3 xzxz1">
              <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
              <div class="nav3 nav">
                <div class="navxx">
                  <span>服务范围</span>
                  <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                  <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img190.png">
                </div>
              </div>
            </div>
            <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
              <span class="xzxz" @click="din(item)">{{item.cname}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--第一层-->
    <div class="c-first">
      <div class="zss">
        <div class="wan">
          <span class="wan1">定制化开发服务</span>
          <span class="wan2">华钛信息拥有超过多年的IT服务经验，为企业提供敏捷高效的软件开发和运维服务，
助力企业加速数字化进程。</span>
          <span class="an">立即咨询</span>
        </div>
      </div>
    </div>
    <!--    第二层-->
    <div class="c-second">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>服务介绍</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <span class="zi">华钛信息根据相关客户需求，完成软硬件产品的设计及开发工作，最终向客户交付完整的软硬件产品，为客户实现从咨询到落地的全业务过程实施服务。</span>
    </div>
<!--    第三层-->
    <div class="c-thirdy">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>服务优势</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="box4">
          <div class="outer" v-for="(item , index) in loopData" :key="index">
            <img class="dan1" :src="item.icon" />
            <span class="dan2">{{item.xi}}</span>
            <span class="dan3">{{item.xi1}}</span>
          </div>
        </div>
      </div>
    </div>
<!--    第四层-->
    <div class="c-four">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>服务介绍</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <img src="../../assets/img87.png">
    </div>
<!--    底层-->
    <div class="ground-floor">
      <div class="fbox">
        <div class="fbox1">
          <div class="fbox2">
            <div class="lianxi">
              <div class="fico">
                <img class="ficon" src="../../assets/img2.png">
                <p class="fdesignation">华钛信息</p>
              </div>
              <div class="contway">
                <div class="special">
                  <span>联系电话：</span>
                  <span>021-55962658</span>
                </div>
                <div class="special">
                  <span>商务合作：</span>
                  <span>business@huataish.com</span>
                </div>
              </div>
            </div>
            <div class="cut-line"></div>
            <div class="explain">
              <div class="explain1">
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">行业方案</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                        <span @click="din(item)">{{ item.lei }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">自主产品</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                        <span @click="din(item)">{{ item.lei1 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">服务范围</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                        <span @click="din(item)">{{ item.lei2 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="cl()">客户案例</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="hz()">合作伙伴</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="xx()">华钛信息</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                        <span @click="din(item)">{{ item.lei4 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cut-line1"></div>
          <div class="safety">
            <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustoMization",
  data() {
    return {
      cur: 0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopData: [
        {
          icon: require("../../assets/img80.png"),
          xi:"开发能力",
          xi1:"成熟的软件开发能力\n" +
              "已通过美国SEI机构CMMI L3认证",
        },
        {
          icon: require("../../assets/img81.png"),
          xi:"平台支撑",
          xi1:"独具特色的软件技术服务业务支撑平台",
        },
        {
          icon: require("../../assets/img82.png"),
          xi:"行业洞察",
          xi1:"深度沟通合作，定制化解决方案",
        },
        {
          icon: require("../../assets/img83.png"),
          xi:"人力资源",
          xi1:"拥有外聘专家和内部培养的IT技术人员",
        },
        {
          icon: require("../../assets/img85.png"),
          xi:"经验丰富",
          xi1:"行业经验丰富，已累积实施500+技术方案",
        },
        {
          icon: require("../../assets/img86.png"),
          xi:"项目管理",
          xi1:"专业项目管理，产品按时上线，帮助客户节约成本",
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')
    },
    xx(){
      window.open('/information','_blank')
    },
    cl(){
      window.open('/Clien','_blank')
    }
  }

}
</script>

<style scoped>
.customization {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  position: relative;
  width: 100%;
}

/*第一层*/
.c-first {
  width: 100%;
  height: 526px;
  background-image: url("../../assets/img79.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-first .wan {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 648px;
  height: 211px;
  margin: 141px 0 0 0px;
  color: white;
}

.c-first .wan .wan1 {
  width: 294px;
  height: 59px;
  font-size: 42px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 59px;
}

.c-first .wan .wan2 {
  width: 664px;
  height: 68px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 34px;
}

.c-first .wan .an {
  width: 103px;
  height: 32px;
  font-size: 14px;
  background: #2A5CFF;
  box-shadow: 0px 2px 10px 0px rgba(9, 55, 204, 0.31);
  text-align: center;
  line-height: 32px;
}

/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  /*margin-bottom: 48px;*/
  /*background: red;*/
}

.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}

.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}

.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}

.spreadhead .subhead .moit span {
  display: block;
  width: 96px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第二层*/
.c-second {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 286px;
}
.c-second .zi {
  width: 980px;
  height: 76px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(32, 43, 78, 1);
  font-size: 18px;
  letter-spacing: 1px;
  font-family: PingFangSC-Regular;
  line-height: 38px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 14px auto;
}
/*第三层*/
.c-thirdy{
  width: 100%;
  height: 548px;
  background: #F9FAFF;
}
.c-thirdy .box3 {
  width: 100%;
  height: 562px;
  margin-top: 48px;
}
.c-thirdy .box4 {
  display: flex;
  flex-direction: row;
  width: 1053px;
  height: 370px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.c-thirdy .box4 .outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 163px;
  border-radius: 4px;
  width: 250px;
  margin: 0 150px 20px 0;
}
.c-thirdy .box4 .outer .dan1{
  display: block;
  width: 44px;
  height: 46px;
  margin: 0 auto;
}
.c-thirdy .box4 .outer .dan2{
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 25px;
  margin: 0 auto;
}
.c-thirdy .box4 .outer .dan3{
  width: 250px;
  height: 40px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 111, 143, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-thirdy .outer:nth-child(3n) {
  margin-right: 0;
}
.c-thirdy .outer:nth-last-child(-n + 3) {
  margin-bottom: 0;
}
/*第四层*/
.c-four{
  width: 100%;
  height: 840px;
}
.c-four img{
  display: block;
  margin: 0 auto;
}

</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}

.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}

.el-carousel__arrow--left {
  border: 1px solid #124BFF;
}

.el-carousel__arrow--right {
  border: 1px solid #124BFF;
}

.el-carousel__arrow i {
  color: white;
}
</style>